<template lang="pug">
	.page
		Menu
			template(#title)
				.flex.items-center
					| Ratings & reviews
					Tag.mt-4.ml-8(:label="target" type="green")
					
		.content
			template(v-if="isMounted")

				select.mt-24(v-model="group")
					option(v-for="item in groupOptions" :value="item") Group By: {{ item }}

				ContainerForData.mt-24(width="100%" :isLoading="isLoading")
					template(#header-left)
						.flex.items-center
							p Average Rating By {{ group }}
							Tooltip.mr-24(
								v-if="false"
								trigger="hover"
							)
								template(slot="reference")
									HelpCircle.icon
								span 'Tooltip'
							p.ml-16 Total Review Count: {{ response.summary.total }}
							p.ml-16 Total Average Rating: {{ response.summary.averageValue.toFixed(1) }}
					template(#header-right)
						select(v-model="average")
							option(v-for="item in averageRating" :value="item") Average {{ item }}
					template.relative(#data)
						span(v-for="(item, index) in position")
							.absolute(
								v-if="ratingList.length"
								:style="{ top: `${87 + index * 40}px`, left: '25px' }"
							) {{ ratingList[index] }}
							Rating.absolute(
								v-if="position && ratingList.length"
								:style="{ top: `${87 + index * 40}px`, left: '50px' }"
								:rating="ratingList[index]"
							)
						BarChart(
							v-if="average === 'Rating'"
							:chartData="stackedBarChartData"
							:options="stackedBarChartOptions"
							:plugins="[erChartRatingPlugin]"
							ref="bar"
							:style="{ height: `${180 + (40 * (lengthOfResult - 1)) }px` }"
						)
						BarChart(
							v-else
							:chartData="stackedBarChartDataReviews"
							:options="stackedBarChartOptions"
							:plugins="[erChartRatingPlugin]"
							ref="bar"
							:style="{ height: `${180 + (40 * (lengthOfResult - 1)) }px` }"
						)

				ContainerForData.mt-24(width="100%" :isLoading="isLoading")
					template(#header-left)
						.flex.items-center
							p Details
							Tooltip.mr-24(
								v-if="false"
								trigger="hover"
							)
								template(slot="reference")
									HelpCircle.icon
								span 'Tooltip'
					template(#header-right)
						Search(v-model="search" @change="searchByName")
					template(#data)
						Table(v-bind="tableOptions")
							template(#product="{item, index, value}")
								a(
									v-if="item.show_url"
									:href="item.sku_url"
									target="_blank"
								) {{ value.name }}
								.name(v-else) {{ value.name }}
								div
									strong RPC
									span.ml-8 {{ value.rpc }}
								div
									strong UPC
									span.ml-8 {{ value.upc }}
							template(#count="{item, index, value}")
								.flex.items-center.justify-center
									span.mr-8 {{ value }}
									Badge(
										:text="value >= 21 ? 'pass' : 'fail'"
										:color="value >= 21 ? 'green' : 'red'"
									)
							template(#rating="{item, index, value}")
								.flex.items-center.justify-center.mt-24
									span.mr-8 {{ value }}
									Badge(
										:text="value >= 4 ? 'pass' : 'fail'"
										:color="value >= 4 ? 'green' : 'red'"
									)
								Rating(:rating="value")
					template(
						v-if="hasPagination"
						#pagination
					)
						.element-container__content.flex.items-center.justify-center
							pagination(
								:total="response.count"
								:current="current"
								:limit="limit"
								@change-page="current = $event"
							)

			.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import { renameYandexInObject } from '@/helpers/RenameYandexPokupki'
import { mapGetters } from 'vuex'
import Menu from '@/components/Menu/Menu.vue'
import Tooltip from '@/components/Elements/Tooltip.vue'
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'
import ContainerForData from "@/components/Nestle/ContainerForData"
import BarChart from "@/components/Chart/BarChart"
import Search from "@/components/Nestle/Search"
import Table from "@/components/Table/Table"
import Badge from "@/components/Chart/Badge"
import Rating from "@/components/Nestle/Layout/Rating"
import Pagination from "@/components/Pagination/Pagination.vue"
import Tag from '@/components/Tag/Tag'

export default {
	name: "RatingsAndReviews",
	components: {
		Rating,
		Badge,
		Table,
		Search,
		BarChart,
		ContainerForData,
		Tooltip,
		HelpCircle,
		Menu,
		Pagination,
		Tag,
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
    sellers: {
      type: String,
      default: '',
    },
    sellerType: {
      type: String,
      default: '',
    },
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data: () => {
		return {
			target: '60%',
			group: 'Store',
			groupOptions: ['Store', 'Brand', 'Category'],
			average: 'Rating',
			averageRating: ['Rating', 'Reviews'],
			list: [],
			search: null,
			position: [],
			ratingList: [],
			response: null,
			current: 1,
			limit: 20,
			offset: 0,
      selectedType: null,

			groupMapping: {
				Store: {
					name: 'stores',
					nameOfIdKey: 'storeId'
				},
				Brand: {
					name: 'brands',
					nameOfIdKey: 'brandId'
				},
				Category: {
					name: 'categories',
					nameOfIdKey: 'categoryId'
				},
			},

			isLoading: true,
			isMounted: false,
		}
	},
	computed:{
		...mapGetters('globalFilter', [
			'getStoreById',
			'getCategoryById',
			'getBrandById',
		]),
		hasPagination() {
			if (!this.response?.count) return
			return this.response.count / this.limit > 1
		},
		lengthOfResult() {
			if (!this.response[this.groupMapping[this.group].name]) return
			return Object.keys(this.response[this.groupMapping[this.group].name]).length
		},
		stackedBarChartOptions () {
			return {
				indexAxis: 'y',
				plugins: {
					datalabels: {
						display: function (context){
							return context.dataset.data[context.dataIndex] > 0
						},
						color: 'white',
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						font: {
							weight: 'bold',
							size: 14
						}
					},
					responsive: true,
					tooltip: {
						enabled: false
					},
					interaction: {
						intersect: false,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							font: {
								size: 14
							}
						}
					},
					title: {
						position: 'bottom',
						display: true,
						text: 'Product count',
						font: {
							size: 18
						}
					}
				},
        onClick: (event, activeEls) => {
          const datasetIndex = activeEls[0]?.datasetIndex
          const datasetLabel = event.chart.data.datasets[datasetIndex]?.label || null
          if (!datasetLabel) return
          this.selectedType = datasetLabel
          this.offset = 0
          this.current = 1
          this.fetch()
        },
				responsive: true,
				scales: {
					x: {
						stacked: true,
						beginAtZero: true,
						ticks: {
							maxTicksLimit: 50,
						},
					},
					y: {
						stacked: true,
					}
				}
			}
		},
		stackedBarChartData() {
			if (!this.response[this.groupMapping[this.group].name]) return
			return {
				labels: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item.name || 'Undefined'),
				percent: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item.rating),
				datasets: [
					{
						label: '1',
						borderColor: '#E55627',
						backgroundColor: '#E55627',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item?.scores?.[1] || 0)
					},
					{
						label: '2',
						borderColor: '#FB8A02',
						backgroundColor: '#FB8A02',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item)=>item?.scores?.[2] || 0)
					},
					{
						label: '3',
						borderColor: '#FFC700',
						backgroundColor: '#FFC700',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item)=>item?.scores?.[3] || 0)
					},
					{
						label: '4',
						borderColor: '#C3C12A',
						backgroundColor: '#C3C12A',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item)=>item?.scores?.[4] || 0)
					},
					{
						label: '5',
						borderColor: '#49A22F',
						backgroundColor: '#49A22F',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item)=>item?.scores?.[5] || 0)
					},
					{
						label: 'not rated',
						borderColor: '#ADACAD',
						backgroundColor: '#ADACAD',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item?.scores?.['notRated'] || 0)
					},
				]
			}
		},
		stackedBarChartDataReviews() {
			return {
				labels: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item.name || 'Undefined'),
				percent: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item.rating),
				datasets: [
					{
						label: 'Reviews',
						borderColor: '#49A22F',
						backgroundColor: '#49A22F',
						data: Object.values(this.response[this.groupMapping[this.group].name]).map((item) => item?.reviews || 0)
					},
				]
			}
		},
		tableDataDefault() {
			return this.response.products
		},
		tableColumns() {
			return [
				{
					title: 'Online Store',
					width: 80,
					cellClass: 'justify-center',
					value: ({ storeId }) => this.getStoreById(Number(storeId)),
				},
				{
					title: 'Category',
					width: 80,
					cellClass: 'justify-center',
					value: ({ categoryId }) => this.getCategoryById(Number(categoryId)),
				},
				{
					title: 'Brand',
					width: 80,
					cellClass: 'justify-center',
					value: ({ brandId }) => this.getBrandById(Number(brandId)),
				},
				{
					title: 'Trusted Product Description',
					width: 300,
					slot: 'product',
					cellClass: 'justify-center',
					value: ({ name, upc, rpc }) => ({ name, upc, rpc }),
				},
				{
					title: 'Review count',
					width: 80,
					slot: 'count',
					cellClass: 'justify-center',
					value: ({ reviewsCount }) => Number(reviewsCount),
				},
				{
					title: 'Avg Rating',
					width: 80,
					slot: 'rating',
					cellClass: 'justify-center',
					value: ({ ratingValue }) => (
						Number(ratingValue ? ratingValue : 0).toFixed(1)
					),
				},
			]
		},
		tableOptions() {
			return {
				sort: {
					field: 'name',
					order: 'desc',
				},
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
		tableData() {
			return this.list.concat(this.tableDataDefault)
		},
		erChartRatingPlugin() {
			return {
				id: 'chartRating',
				afterDatasetUpdate: this.erChartRating()
			}
		},
	},
	methods: {
		async erChartRating() {
			await this.$nextTick(()=>{
				const newChart = this.$refs?.bar?.chart
				newChart._metasets[0].data.forEach((bar) => {
					this.position.push(bar.y)
				})
				const ratings = newChart?.config?._config?.data?.percent
				this.ratingList = ratings.map(item => item.toFixed(1))
			})
		},
		async updateRatings() {
			await this.erChartRating()
		},
		async fetch() {
			this.isMounted = true;
			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
        sellers: this.sellers,
        sellerType: this.sellerType,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
        type: this.selectedType,
				searchQuery: this.search || null,
			};

			try {
				this.isLoading = true
				const result = await this.$api.nestle.getRatingStore(params)
				const renamedItems = renameYandexInObject(result.stores)
				this.response = result
				this.response.stores = renamedItems
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoading = false
			}
		},
		searchByName() {
			this.offset = 0
			this.current = 1
			this.fetch()
		},

	},
	watch: {
		// TODO Оптимизировать wathcer
		async group() {
			this.offset = 0
			this.current = 1
			await this.fetch()
			await this.updateRatings()
		},
		needUpdateResult: {
			async handler() {
				this.offset = 0
				this.current = 1
				await this.fetch()
				await this.updateRatings()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},
}
</script>

<style scoped lang="scss">

.page {
	max-width: 1280px;
	margin: 0 auto;
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;

		&-icon {
			margin-right: 4px;
		}

		::v-deep.control__input{
			color: color(white);
			font-weight: 400;
		}
	}
}
h1{
	color: color(gray-700);
}
.header{
	margin-bottom: 32px;
	&-right{
		margin-left: auto;
	}
	&-button {
		background-color: #fff;
		padding: 10px 5px;
		border-radius: 3px;
		color: color(gray-700);
		border: 1px color(gray-400) solid;
		&:not(:last-child){
			margin-right: 8px;
		}
		&:focus{
			outline: none;
		}
	}
}
.icon{
	color: color(gray-500);
	position: absolute;
	margin-left: 8px;
	margin-top: -6px;
	::v-deep.material-design-icon__svg {
		width: 15px;
		height: 15px;
	}
}
.icon-button{
	color: color(gray-700);
	::v-deep.material-design-icon__svg {
		width: 15px;
		height: 15px;
	}

	margin-right: 8px;
}
::v-deep.control__input{
	padding: 10px 5px;
	border: none;
	width: 80px;
	font-weight: 800;
	color: color(gray-700);
	cursor: pointer;

	&:focus{
		outline: none;
	}
}

select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}

::v-deep.chart {
	width: 1050px;
	margin-left: auto;
}

.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.name {
	cursor: default
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
